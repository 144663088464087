import { ENVIRONMENT_INITIALIZER, inject, InjectionToken } from '@angular/core';
import { CookieService, LocalstorageService } from 'ngx-unificator/services';
import { of } from 'rxjs';
import { createABTestDebugMessage } from './ab-test-debug';
import { AB_TEST_LIST } from './ab-test.data';

function initABTest(ABTestList: any) {
  const cookie = inject(CookieService);
  const storage = inject(LocalstorageService);
  return () => {
    const headerMainGroupSet = new Set<string>();
    Object.keys(ABTestList || {}).forEach(e => {
      if (headerMainGroupSet.has(ABTestList[e]?.headerMainGroup)) {
        throw new Error(`Duplicated ABTest headerMainGroup: ${ABTestList[e]?.headerMainGroup}`);
      }
      headerMainGroupSet.add(ABTestList[e]?.headerMainGroup);
      ABTestList[e].abTestVariants = Object.keys(ABTestList[e]['abTestGroupIds'])?.map(k => ABTestList[e]['abTestGroupIds'][k]);
      const resolvedCookieValue = ABTestList[e]?.abTestVariants?.filter(v => cookie?.get(v))[0] || null;
      AB_TEST_LIST[e] = {
        ...ABTestList[e],
        nameOfTask: e,
        resolvedCookieValue,
        resolvedValue: resolvedCookieValue || null,
        resolvedValue$: resolvedCookieValue ? of(resolvedCookieValue) : of(null),
      };

      if (AB_TEST_LIST[e]?.debug && AB_TEST_LIST[e]?.resolvedCookieValue) {
        createABTestDebugMessage(storage, e, `value resolved from cookie ${AB_TEST_LIST[e].resolvedCookieValue}`);
      }
    });
    return true;
  };
}

const AB_TEST_LIST_PARAM = new InjectionToken<any>('ABTestListParam');

export const provideABTest = (ABTestListParam: any = null) => {
  return [
    { provide: AB_TEST_LIST_PARAM, useValue: ABTestListParam },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: () => initABTest(ABTestListParam),
      deps: [AB_TEST_LIST_PARAM],
      multi: true
    }
  ];
};
