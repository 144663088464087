@if (!loader.loaded) {
  <div class="preloader">
    @if (theme.changeTheme$ | async) {
      <img [src]="'/assets/img/theme/logo.png' | logoType" alt="maneki">
    }
  </div>
}
<router-outlet></router-outlet>


